import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { ExtCardInternalStatusResultModel, ProgramV1GetProgramDefaultResponseData, ProgramV1GetProgramDefaultResponse } from '@trialcard/apigateway.models';
import { Resolve } from 'vue-di';
import { EdgeProgramApi } from '@trialcard/apigateway.client';
import { OnClear } from '~/services/clearModule';
import { clearRecords } from '~/services/clearRecords';
import { Settings } from '~/services/settings';
import EnrollmentModule from '~/store/enrollment';
import productList from '~/assets/products';
import programs from '~/assets/programs';
import DocumentModule from '~/store/document';

export interface IProgram {
    tcServicePrgId: number | null;
    quickPathId: number | null;
    name: string;
}

export interface IIdMap {
    [key: number] : number;
}

@InjectModule({ stateFactory: true }, module)
@OnClear<ProgramModule>(async value => value.clear())
export default class ProgramModule extends InjectVuexModule {
    @Resolve
    public settings!: Settings;
    @Resolve
    public enrollment!: EnrollmentModule;
    @Resolve
    public document!: DocumentModule;
    @Resolve
    public edgeProgramApi!: EdgeProgramApi;


    public program: IProgram = {
        tcServicePrgId: null,
        quickPathId: null,
        name: '',
    };

    public products = [];
    public productList = productList


    @Mutation
    public clear() {
        clearRecords(this.program);
    }


    @Mutation
    public setProducts(products: any){
        this.products = products
    }

    @Mutation
    public updateProgram(program: IProgram) {
        this.program = {
            ...this.program,
            ...program,
        }
    }

    @Action({})
    public async checkProgramInformation(checkNumResult: ExtCardInternalStatusResultModel) {
        const { tcServicePrgId, promotionId, ndcs, memberNumber } = checkNumResult;
        let isInvalid = true;
        if (tcServicePrgId && promotionId && ndcs && memberNumber) {
            const products = ndcs.map(drug => ({
                name: `${drug.name}${drug.description ? ' ' + drug.description : ''}`,
                ndc: drug.ndc,
            }));
            this.setProducts(products);
            const { success, data } = await this.getProgramInformation(Number(promotionId));
            if (success && data &&  Object.keys(data).length > 0) {
                const { programId: quickPathId, programName: name } = data;
                this.updateProgram({
                    tcServicePrgId: Number(tcServicePrgId),
                    quickPathId,
                    name,
                });
                this.document.updatePDFData({
                    ...this.document.pdfData,
                    memberNumber,
                    programName: this.program.name,
                })
                isInvalid = false;
            }
        }
        return isInvalid
    }

    @Action({})
    async getProgramInformation(promotionId: number) {
        let result = {
            success: false,
            messages: [] as string[],
            data: {} as ProgramV1GetProgramDefaultResponseData
        } as ProgramV1GetProgramDefaultResponse;
        try {
            const getProgramResult = (await this.edgeProgramApi.programV1GetProgram({promotionId, programCode: ''}))
            const data = getProgramResult && getProgramResult.data || {};
            if (Object.keys(data).length === 0) {
                throw new Error('Missing program Information');
            }
            result = data

        } catch(error) {
            // eslint-disable-next-line no-console
            console.error('Error from getting program information',error)
        }
        return result;
    }
}
