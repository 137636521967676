/* eslint-disable dot-notation */
import Vue from 'vue';
import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import moment from 'moment';
import { EdgeDocumentsApi } from '@trialcard/apigateway.client';
import { PDFDocument } from 'pdf-lib';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { PlpDocType } from './plpEnums';
import ProgramModule from './program';
import EnrollmentModule from '~/store/enrollment';
import PatientModule from '~/store/patient';
import products from '~/assets/products';
import { Settings } from '~/services/settings';

const capitalize = (str: string) =>
    str
        .split(' ')
        .map(str =>
            str
                .charAt(0)
                .toUpperCase()
                .concat(str.slice(1))
        )
        .join(' ');
@InjectModule({ stateFactory: true }, module)
export default class DocumentModule extends InjectVuexModule {
    @Resolve
    enrollment!: EnrollmentModule;

    @Resolve
    edgeDocumentsApi!: EdgeDocumentsApi;

    @Resolve
    patient!: PatientModule;

    @Resolve
    program!: ProgramModule;

    @Resolve
    public settings!: Settings;

    public hasDocument = false;
    public pdfData = {
        product: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        email: '',
        dob: '',
        gender: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        prescriptionNumber: '',
        prescriptionFilledDate: '',
        amountPaid: '',
        pharmacyName: '',
        pharmacyAddress1: '',
        pharmacyAddress2: '',
        pharmacyCity: '',
        pharmacyState: '',
        pharmacyZip: '',
        quantity: '',
        supply: '',
        signature: '',
        patientCertificationRead: null,
        paymentPreference: '',
        memberNumber: '',
        programName: '',
    };

    public products = products;

    @Action({ rawError: true })
    clear() {
        this.updateDocumentStatus(false);
    }


    @Action({ rawError: true })
    setDocumentStatus(newDocumentStatus: boolean) {
        this.hasDocument = newDocumentStatus;
    }

    @Mutation
    updatePDFData(pdfData: typeof DocumentModule.prototype.pdfData) {
        this.pdfData = pdfData;
    }

    @Action({ rawError: true })
    public async fillDocument() {
        const { firstName, lastName, middleInitial, paymentPreference } = this.pdfData;
        // removing html tags for human readability on PDF
        const product = this.pdfData.product.replace(/<[^>]*>/g, '') || ''
        const fileData = [
            { name: 'memberNumber', value: this.pdfData.memberNumber || '' },
            { name: 'product', value: product },
            { name: 'email', value: this.pdfData.email || '' },
            { name: 'dob', value: this.pdfData.dob || '' },
            { name: 'gender', value: this.pdfData.gender },
            { name: 'address1', value: this.pdfData.address1 || '' },
            { name: 'address2', value: this.pdfData.address2 || '' },
            { name: 'cityStateZip', value: `${capitalize(this.pdfData.city)}, ${this.pdfData.state} ${this.pdfData.zip}`},
            { name: 'amountPaid', value: this.pdfData.amountPaid },
            { name: 'pharmacyName', value: this.pdfData.pharmacyName || '' },
            { name: 'pharmacyAddress1', value: this.pdfData.pharmacyAddress1 || '' },
            { name: 'pharmacyAddress2', value: this.pdfData.pharmacyAddress2 || '' },
            { name: 'pharmacyCityStateZip', value: `${capitalize(this.pdfData.pharmacyCity)}, ${this.pdfData.pharmacyState} ${this.pdfData.pharmacyZip}`},
            { name: 'prescriptionNumber', value: this.pdfData.prescriptionNumber || '' },
            { name: 'prescriptionFilledDate', value: this.pdfData.prescriptionFilledDate || '' },
            { name: 'quantity', value: this.pdfData.quantity || '' },
            { name: 'supply', value: this.pdfData.supply || '' },
            { name: 'fullName', value: capitalize(`${firstName}${middleInitial ? ` ${middleInitial}` : ''} ${lastName}`) },
            { name: 'currentDate', value: moment().format('L') },
            { name: 'signature', value: this.pdfData.signature || '' },
            { name: 'paymentPreference', value: paymentPreference || '' },
            { name: 'patientCertificationRead', value: this.pdfData.patientCertificationRead || '' },
            { name: 'programName', value: this.pdfData.programName || '' },

        ];


        // Below included to allow testing from localhost.
        const url = window.location.href.includes('localhost')
            ? 'https://portal-qa.trialcard.com/TCReimbursement/summary'
            : window.location.href;
        const document = await this.edgeDocumentsApi.fillablePdfGenerateFillablePdf({
            fileUrl:  url.replace(/summary\/?/, 'reimbursement_form.pdf'),
            outputFileName: 'copay-card',
            fileData,
        });

        return document;
    }

    async base64ToArrayBuffer(data: string) {
        const input = data.substring(data.indexOf(',') + 1);
        const binaryString = window.atob(input);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName: string, bytes: Uint8Array) {
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = reportName;
        link.download = fileName;
        link.click();
    }

    // async createBlobForUpload(reportName: string, pdfFile: Uint8Array) {
    //     const fileBlob = new Blob([pdfFile.buffer], { type: 'applicaton/pdf'})
    //     console.log('fileBlob from createBlobForUpload:', fileBlob);
    //     const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
    //     console.log('file from createBlobForUpload:', file);
    //     // this.enrollment.addReimbursementForm({ file, docType: PlpDocType['Claim']})
    //     const fileWithUrl = { blob: '', file, headers: {} }
    //     fileWithUrl.blob = URL.createObjectURL(file)
    //     console.log('fileWithUrl from createBlobForUpload:', fileWithUrl);
    //     if (this.enrollment.addReimbursementForm) {
    //         console.log('first if condition')
    //         this.enrollment.addReimbursementForm(fileWithUrl)
    //     } else {
    //         await this.stall(1000);
    //         console.log('second if condition',  this.enrollment.addReimbursementForm)
    //         this.enrollment.addReimbursementForm(fileWithUrl)
    //     }
    // }

    async createBlobForMergedFiles(reportName: string, pdfFile: Uint8Array) {
        const fileBlob = new Blob([pdfFile.buffer], { type: 'applicaton/pdf'})
        const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
        const mergedPdf = { file, docType: PlpDocType['Claim']}
        return mergedPdf
    }

    @Mutation
    updateDocumentStatus(newDocumentStatus: boolean) {
        this.setDocumentStatus(newDocumentStatus);
    }

    @Mutation
    updatePatientSignature(signature: string){
        this.pdfData.signature = signature
    }
}
