/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi, EnrollmentExtV2Api, EdgeCouponApi, EdgePlpApi } from '@trialcard/apigateway.client';
import {
    SubmitClaimResultModel,
    SubmitClaimResultModelResponse
} from '@trialcard/apigateway.models';
import { AxiosError, AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';
import { union, unionBy } from 'lodash';
import { string } from 'yargs';
import { DocumentFunctions } from '../components/documents/document-functions'
import {
    PlpDocType,
} from '~/store/plpEnums';
import { clearRecords } from '~/services/clearRecords';
import { Settings } from '~/services/settings';
import PatientModule from '~/store/patient';
import { VUFile } from '~/types/componentTypes';
import Document from '~/store/document';

export interface PatientInformationData {
    patientId: string;
    address1: string;
    address2: string;
    gender: string;
    dateOfBirth: string;
    postalCode: string;
    city: string;
    state: string;
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
}

// export interface PatientInformationModule {
//     patientInformation: PatientInformationData;
//     updatePatientInformation(data: PatientInformationData): void;
// }

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public edgePlpApi!: EdgePlpApi

    @Resolve
    public enrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public EdgeCouponApi!: EdgeCouponApi;

    @Resolve
    public patient!: PatientModule;

    @Resolve
    public documentStore!: Document;

    @Resolve
    public documentFunctions!: DocumentFunctions;

    @Mutation
    public updateSurveySessionId(newId: number) {
        this.surveySessionId = newId;
    }

    alternativeEnrollment: boolean | null = null;

    surveySessionId = 0;

    public demographicInformation = {
        firstName: '',
        middleName: '',
        lastName: '',
        mobilePhone: '',
        email: '',
        dateOfBirth: '',
        gender: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
    };

    public insuranceVerification = {
        insuranceType: 1,
        payerName: '',
        payerMedicalId: ''
    };

    public otherInformation = {
        paymentPreference: '',
        productPurchased: '',
        daySupply: '',
        quantity: '',
        pharmacyType: '',
        remainingCost: '',
        totalCost: '',
    };

    public signature = '';

    public promoId = null as null | number | undefined;

    public survey = [{}]

    private files: VUFile[] = [];

    public receipts = {
        files: [] as Array<{ id: string | number, batch: string, file: File; docType: PlpDocType }>
    }

    public docsForDisplay: VUFile[] = [];
    public docsForUpload: VUFile[] = [];

    public products = []

    public program = {
        id: +'',
        quickPathId: +'',
        name: ''
    };

    @Mutation
    public setProgram(program: typeof EnrollmentModule.prototype.program){
        this.program = program
    }

    @Mutation
    public clear() {
        clearRecords(this.demographicInformation)
        clearRecords(this.insuranceVerification)
        clearRecords(this.otherInformation)
        clearRecords(this.receipts)
        clearRecords(this.promoId)
        clearRecords(this.signature)
        this.docsForDisplay = [] as VUFile[]
        this.docsForUpload = [] as VUFile[]
    }

    @Mutation
    public copyVuFile(files: VUFile[]) {
        this.files = files
    }

    @Mutation
    public setPromoId(promoId: number | undefined | null){
        this.promoId = promoId
    }

    @Mutation
    public updateDemographicInformation(data: typeof EnrollmentModule.prototype.demographicInformation) {
        Object.assign(this.demographicInformation, data);
    }

    @Mutation
    public updateInsuranceVerification(data: typeof EnrollmentModule.prototype.insuranceVerification) {
        Object.assign(this.insuranceVerification, data);
    }

    @Mutation
    public updateOtherInformation(data: typeof EnrollmentModule.prototype.otherInformation) {
        Object.assign(this.otherInformation, data);
    }

    @Mutation
    public updateFiles(data: { files: typeof EnrollmentModule.prototype.receipts, batch?: string }) {
        this.receipts = {
            files: unionBy((this.receipts.files || []).filter(f => f.batch !== data.batch), data.files.files || [], x => x.id),
        };
    }

    @Mutation
    public clearFiles() {
        this.receipts = {
            files: [],
        };
    }

    @Mutation
    public addReimbursementForm(data: any) {
        // this.receipts.files.push(data as any)
        this.docsForUpload.push(data);
    }

    @Mutation
    public updateDocsForDisplay(data: typeof EnrollmentModule.prototype.docsForDisplay) {
        this.docsForDisplay = union(this.docsForDisplay, data).filter(d => this.receipts.files.map(f => f.id).includes(d.id));
    }

    @Mutation
    public updateDocsForUpload(data: typeof EnrollmentModule.prototype.docsForUpload) {
        this.docsForUpload = union(this.docsForUpload, data).filter(d => this.receipts.files.map(f => f.id).includes(d.id));
    }

    @Mutation
    public clearDocsForDisplay() {
        this.docsForDisplay = [];
    }

    @Mutation
    public clearDocsForUpload() {
        this.docsForUpload = [];
    }

    @Action({})
    public async submit() {

    }

    async uploadDocument(): Promise<{
        success: boolean;
        messages: string[];
        data: SubmitClaimResultModel[];
    }> {
        const files: Array<{ files: File[]; docType: number }> = [];

        const mergedFileByteArray = await this.documentFunctions.mergeFiles(this.docsForUpload)
        const fileBlob = new Blob([mergedFileByteArray.buffer], { type: 'applicaton/pdf'})
        const file = new File([fileBlob], 'reimbursement-form.pdf', { type: 'application/pdf', lastModified: Date.now() })
        const mergedPdf = { file, docType: PlpDocType['Claim']}

        files.push({ files: [mergedPdf.file], docType: mergedPdf.docType })

        const uploadDocumentResponse = {
            success: true,
            messages: [] as string[],
            data: [] as SubmitClaimResultModel[],
        };
        try {
            if (files.length > 0) {
                const submitClaimResponses = await Promise.all(
                    files.map(async f => {
                        const form = new FormData();
                        form.append('programId', '286')
                        form.append('memberNumber', this.documentStore.pdfData.memberNumber)
                        form.append('body[docType]', f.docType.toString())
                        f.files.map(async x => {
                            form.append('body[files]', x);
                        });
                        const axios: AxiosInstance = (this.EdgeCouponApi as any).axios;
                        const axiosRequest: AxiosRequestConfig = {
                            url: '/edge/coupon/Documents/submitDocuments',
                            method: 'POST',
                            data: form,
                            headers: {
                                'x-program-id': '286',
                            },
                        };
                        await Promise.all([
                            (this.EdgeCouponApi as any).authentications.ApiKey.applyToRequest(axiosRequest),
                            (this.EdgeCouponApi as any).authentications.Bearer.applyToRequest(axiosRequest),
                            (this.EdgeCouponApi as any).authentications.Token.applyToRequest(axiosRequest),
                        ]);
                        return axios.request(axiosRequest) as AxiosPromise<SubmitClaimResultModelResponse>;
                    })
                );
                for (const response of submitClaimResponses) {
                    if (!response.data.success) {
                        uploadDocumentResponse.success = false;
                        uploadDocumentResponse.messages.push(...response.data.messages);
                    } else {
                        uploadDocumentResponse.data.push(response.data.data);
                    }
                }
            }
            return uploadDocumentResponse;
        } catch (error) {
            // log error
            console.log(error)
            return uploadDocumentResponse;
        }
    }
}
