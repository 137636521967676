

































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { Resolve } from 'vue-di';
import { faPrescription, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { mapKeys } from 'lodash';
import FloatingFooter from '~/components/floating-footer.vue';
import theme from '~/settings/theme';
import SessionModule from '~/store/session';
import LayoutModule from '~/store/layout';
import IriModule from '~/store/iri';
import ProgramModule from '~/store/program';

@Component<DefaultLayout>({
    components: {
        FloatingFooter,
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((this as any).$meta) (this as any).$meta().refresh();
    },
    icons: {
        faPrescription,
        faChevronUp,
        faChevronDown,
    },
})
export default class DefaultLayout extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Resolve
    public iri!: IriModule;

    @Resolve
    public program!: ProgramModule;

    @Resolve
    public session!: SessionModule;
    public theme = theme;
    public showFooterLinks = true;

    @Watch('$route.name', { immediate: true })
    public watchRoute(route: string) {
        this.showFooterLinks = true
        if (['index', 'member-number'].includes(route)) {
            this.showFooterLinks = false;
        }
    }

    @Watch('this.$route.query', { immediate: true })
    setIsTestingPdf() {
        const isTestingPdf = !!this.$route.query.testPdf && this.$route.query.testPdf === 'true';
        this.layout.setIsTestingPdf(isTestingPdf);
    }

    public get drawer() {
        return this.layout.drawer;
    }
    public set drawer(v) {
        this.layout.setDrawer(v);
    }
    public get mini() {
        return this.layout.mini;
    }
    public set mini(v) {
        this.layout.setMini(v);
    }
    public get iriOpen() {
        return this.iri.open;
    }
    public set iriOpen(v) {
        this.iri.toggleIriOpen(v);
    }

    public get menu(): object {
        return this.$settings.mainMenu;
    }

    public back() {
        this.$router.back();
    }

    public linkMap = {
        // Add additional programs here.  Key should be tcServicePrgId and value should be link urls
        // Don't forget to also add programs in the program store and in the program.ts in the assets folder
        1099: {
            about: 'https://corp.trialcard.com/',
            privacy: this.$settings.url('/privacy-policy'),
            disclaimer: this.$settings.url('/disclaimer'),
            TC: this.$settings.url('/termsandconditions'),
        }
    }

    public get footerLinks() {
        const programId = this.program.program.tcServicePrgId as keyof typeof DefaultLayout.prototype.linkMap
        let links = {
            about: '#',
            privacy: '#',
            disclaimer: '#',
            TC: '#',
        };
        this.showFooterLinks = false;
        if (programId && this.linkMap[programId]) {
            this.showFooterLinks = true
            links = this.linkMap[programId];
        }
        return links;
    }

    public get brkPoints() {
        return {
            'brk-xs': this.$vuetify.breakpoint.xs,
            'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
            'brk-sm': this.$vuetify.breakpoint.sm,
            'brk-sm-only': this.$vuetify.breakpoint.smOnly,
            'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
            'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
            'brk-md': this.$vuetify.breakpoint.md,
            'brk-md-only': this.$vuetify.breakpoint.mdOnly,
            'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
            'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
            'brk-lg': this.$vuetify.breakpoint.lg,
            'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
            'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
            'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
            'brk-xl': this.$vuetify.breakpoint.xl,
            'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
        };
    }
}
