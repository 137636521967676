import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { OnClear } from '~/services/clearModule';

@InjectModule({ stateFactory: true }, module)
@OnClear<LayoutModule>(async value => value.clear())
export default class LayoutModule extends InjectVuexModule {
    public drawer = true;
    public mini = false;
    public isTestingPdf = false;

    @Mutation
    public setMini(mini: boolean): void {
        this.mini = mini;
    }

    @Mutation
    public toggleMini(): void {
        this.mini = !this.mini;
    }

    @Mutation
    public setDrawer(drawer: boolean): void {
        this.drawer = drawer;
    }

    @Mutation
    public toggleDrawer(): void {
        this.drawer = !this.drawer;
    }

    @Mutation
    public clear() {
        this.drawer = true;
        this.mini = false;
    }

    @Mutation
    public setIsTestingPdf(isTestingPdf: boolean): void {
        this.isTestingPdf = isTestingPdf;
    }
}
