import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _79a5a8ff = () => interopDefault(import('..\\pages\\disclaimer.vue' /* webpackChunkName: "pages_disclaimer" */))
const _59f08a58 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _aac67b66 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _52f1d195 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _6cbbf15e = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _1b2a33cc = () => interopDefault(import('..\\pages\\have-a-card\\rejection.vue' /* webpackChunkName: "pages_have-a-card_rejection" */))
const _010bc986 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _7203e4a8 = () => interopDefault(import('..\\pages\\member-number.vue' /* webpackChunkName: "pages_member-number" */))
const _6ed3525f = () => interopDefault(import('..\\pages\\need-a-card.vue' /* webpackChunkName: "pages_need-a-card" */))
const _255187be = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _fc62e26a = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _30b7447a = () => interopDefault(import('..\\pages\\need-a-card\\rejection.vue' /* webpackChunkName: "pages_need-a-card_rejection" */))
const _925afba0 = () => interopDefault(import('..\\pages\\patient-information.vue' /* webpackChunkName: "pages_patient-information" */))
const _f3909946 = () => interopDefault(import('..\\pages\\prescription-information.vue' /* webpackChunkName: "pages_prescription-information" */))
const _45cf1b0a = () => interopDefault(import('..\\pages\\privacy-policy.vue' /* webpackChunkName: "pages_privacy-policy" */))
const _2a65bdfe = () => interopDefault(import('..\\pages\\reimbursement\\index.vue' /* webpackChunkName: "pages_reimbursement_index" */))
const _6894f64f = () => interopDefault(import('..\\pages\\success.vue' /* webpackChunkName: "pages_success" */))
const _6d13d472 = () => interopDefault(import('..\\pages\\summary.vue' /* webpackChunkName: "pages_summary" */))
const _02b6928c = () => interopDefault(import('..\\pages\\termsandconditions.vue' /* webpackChunkName: "pages_termsandconditions" */))
const _085fc7b0 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _1fea8275 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _1ff899f6 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _2014c8f8 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _2022e079 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _54d0bdb6 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _e17cc6c4 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/disclaimer",
    component: _79a5a8ff,
    name: "disclaimer"
  }, {
    path: "/error",
    component: _59f08a58,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _aac67b66,
    children: [{
      path: "",
      component: _52f1d195,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _6cbbf15e,
      name: "have-a-card-enrollment"
    }, {
      path: "rejection",
      component: _1b2a33cc,
      name: "have-a-card-rejection"
    }, {
      path: "success",
      component: _010bc986,
      name: "have-a-card-success"
    }]
  }, {
    path: "/member-number",
    component: _7203e4a8,
    name: "member-number"
  }, {
    path: "/need-a-card",
    component: _6ed3525f,
    name: "need-a-card",
    children: [{
      path: "eligibility",
      component: _255187be,
      name: "need-a-card-eligibility"
    }, {
      path: "insurance",
      component: _fc62e26a,
      name: "need-a-card-insurance"
    }, {
      path: "rejection",
      component: _30b7447a,
      name: "need-a-card-rejection"
    }]
  }, {
    path: "/patient-information",
    component: _925afba0,
    name: "patient-information"
  }, {
    path: "/prescription-information",
    component: _f3909946,
    name: "prescription-information"
  }, {
    path: "/privacy-policy",
    component: _45cf1b0a,
    name: "privacy-policy"
  }, {
    path: "/reimbursement",
    component: _2a65bdfe,
    name: "reimbursement"
  }, {
    path: "/success",
    component: _6894f64f,
    name: "success"
  }, {
    path: "/summary",
    component: _6d13d472,
    name: "summary"
  }, {
    path: "/termsandconditions",
    component: _02b6928c,
    name: "termsandconditions"
  }, {
    path: "/unlock",
    component: _085fc7b0,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _1fea8275,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _1ff899f6,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _2014c8f8,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _2022e079,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _54d0bdb6,
    name: "errors-500"
  }, {
    path: "/",
    component: _e17cc6c4,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
